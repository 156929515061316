import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import BgImage from "../../static/img/bg4.jpg"

const IndustriesPage = () => (
  <Layout>
    <Parallax bgImage={BgImage} className="text-center parallax-secondary">
      <div className="container-fluid">
        <div className="row">
          <div className="col"></div>
          <div className="col-md-7">
            <h1 className="text-white wow fadeInDown">INDUSTRIES</h1>
            <h3 className="text-white wow fadeInUp subtitle">
              Salvex is a global auction platform that handles the sale of
              surplus, salvage, scrap, damaged, bankrupt inventories for asset
              recovery, insurance, transportation
            </h3>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </Parallax>
    <div className="container-fluid pb-4">
      <div className="container mb-4">
        <div className="row">
          <div className="col pt-4">
            <h1 className="h1 text-primary mt-4 text-center wow fadeInUp">
              Industries
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <span
                  className="icon-agriculture"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">
                  Agriculture
                </h2>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInLeft pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">
                  Corporate Asset Recovery
                </h2>
              </div>
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInRight pt-4 pb-4 bg-alt">
                <span
                  className="icon-corporate"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <span
                  className="icon-oilgas"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">
                  Oil &amp; Gas and Utilities
                </h2>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInLeft pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">
                  Solar and Wind
                </h2>
              </div>
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInRight pt-4 pb-4 bg-alt">
                <span
                  className="icon-solar"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <span
                  className="icon-insurance"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">Insurance</h2>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInLeft pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">Government</h2>
              </div>
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInRight pt-4 pb-4 bg-alt">
                <span
                  className="icon-government"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInLeft pt-4 pb-4 bg-alt">
                <span
                  className="icon-heavy-equipment"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInRight pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">
                  Heavy Equipment
                </h2>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>

        <div className="row">
          <div className="col"></div>
          <div className="col-10">
            <div className="row mt-4 mb-4 bg-white border rounded">
              <div className="col-sm-8 col-md-5 col-lg-8 text-center wow fadeInLeft pt-4 pb-4">
                <h2 className="h3 text-secondary mt-4 pt-4 mb-0">
                  Transportation
                </h2>
              </div>
              <div className="col-sm-8 col-md-3 col-lg-4 text-center wow fadeInRight pt-4 pb-4 bg-alt">
                <span
                  className="icon-transportation"
                  style={{ fontSize: "140px" }}
                ></span>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndustriesPage
